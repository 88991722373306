<script setup lang="ts">
type ContainerProps = {
  class?: string | Record<string, boolean>
}

const props = defineProps<ContainerProps>()
</script>

<template>
  <section
    class="flex flex-col w-full max-w-[62.5rem] gap-8 h-full"
    :class="props.class"
  >
    <slot />
  </section>
</template>
